import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';

import SeoHelmet from '../components/SeoHelmet';
import DefaultLayout from '../components/DefaultLayout';

const Title = styled.h1`
  margin: 48px 0;
  text-align: center;

  @media (max-width: 1023px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

const SectionTitle = styled.h2`
  margin: 48px 0 24px;

  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const Section = styled.div`
  position: relative;
  margin: 140px 0 72px;
`;

const MoreInfo = styled.p`
  font-family: Roboto;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: -0.025em;
`;

const MoreInfoLink = styled.a`
  text-decoration-line: underline;
  color: #7774ff;
  transition: color 200ms;

  &:hover {
    color: #5451C9;
  }
`;

const CookiesPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query CookiesPageQuery {
      site {
        siteMetadata {
          siteName
          siteUrl
          links {
            privacy
            terms
          }
        }
      }
    }
  `);
  const siteName = _.get(data, 'site.siteMetadata.siteName');
  const siteUrl = _.get(data, 'site.siteMetadata.siteUrl');
  const privacyUrl = _.get(data, 'site.siteMetadata.links.privacy');
  const termsUrl = _.get(data, 'site.siteMetadata.links.terms');

  return (
    <DefaultLayout>
      <SeoHelmet
        location={location}
        title={`Как ${siteName} использует куки`}
        description="Максимально коротко как Nerdia использует куки"
      />
      <Section>
        <Title>Как {siteName} использует cookie</Title>

        <p>
          {siteName}, как и большинство сайтов в интернете, использует cookie:
          это дает нам возможность предоставлять пользователям свои услуги,
          следить за тем, что наши сервисы доступны и не упали, а также
          анализировать данные и понимать, что мы делаем не так и куда развивать
          нашу платформу. В этом документе мы объясняем, как мы используем
          cookie и как вы можете их отключить.
        </p>

        <SectionTitle>Что такое cookie?</SectionTitle>
        <p>
          В целом это печенье. Но не в нашем случае. Cookie — это фрагмент
          данных сайта, на который вы зашли и который сохраняется на вашем
          компьютере.
        </p>

        <SectionTitle>Какие cookiе мы используем и с какой целью?</SectionTitle>
        <p>
          <b>Cookie для статистики:</b> мы используем несколько систем для сбора
          статистики — нам важно знать, как используют нашу платформу. На основе
          этих данных мы понимаем размер нашей аудитории, а также что мы делаем
          правильно и что неправильно. Мы осуществляем сбор статистики через
          партнеров — Google Analytics, Yandex Metrika.
        </p>
        <p>
          <b>Cookie для аторизации:</b> мы используем куки для того что бы
          смогли зайти на <a href={siteUrl}>{siteUrl}</a> под своей учетной
          записью. Без этого вы не смогли бы пользоваться нашей платформой.
        </p>

        <SectionTitle>
          Что делать, если вы не хотите использовать cookie?
        </SectionTitle>
        <p>
          Вы можете отключить их в настройках безопасности вашего браузера.
          Важно понимать, что настройки нужно применить во всех браузерах,
          которыми вы пользуетесь (на компьютере и в телефоне). В случае если вы
          решите отключить cookiе, имейте в виду, что часть функций сервисов
          {siteName} перестанут быть вам доступны или могут работать
          непредсказуемо.
        </p>

        <MoreInfo>
          Больше информации:{' '}
          <MoreInfoLink href="/terms">Пользовательское Соглашение</MoreInfoLink>{' '}
          <MoreInfoLink href="/privacy">
            Политика конфиденциальности
          </MoreInfoLink>
        </MoreInfo>
      </Section>
    </DefaultLayout>
  );
};

export default CookiesPage;
